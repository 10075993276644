<template>
  <b-modal v-model="modal" size="lg" id="editSlotGameModal" title="แก้ไขเกม">
    <b-form-group label="ชื่อเกมภาษาไทย" label-for="game_name_th">
      <b-form-input v-model="form.game_name_th"></b-form-input>
    </b-form-group>
    <b-form-group label="ชื่อเกมภาษาอังกฤษ" label-for="game_name_th">
      <b-form-input v-model="form.game_name"></b-form-input>
    </b-form-group>

    <div class="row">
      <div class="col-3">
        <b-form-group label="RTP" label-for="rtp">
          <b-form-input v-model="form.rtp"></b-form-input>
        </b-form-group>
      </div>
      <div class="col-3">
        <b-form-group label="Max Win" label-for="max_win">
          <b-form-input v-model="form.max_win"></b-form-input>
        </b-form-group>
      </div>
    </div>
    <b-form-group label-for="free_spin">
          <b-form-checkbox v-model="form.free_spin" :value="1" :unchecked-value="0">
            ฟรีสปิน
          </b-form-checkbox>
        </b-form-group>
    <b-form-group label="Screenshot 1" label-for="Screenshot 1">
      <b-form-file v-model="form.screenshot1" placeholder="Screenshot1..." accept="image/*"></b-form-file>
    </b-form-group>
    <b-form-group label="Screenshot 2" label-for="Screenshot 2">
      <b-form-file v-model="form.screenshot2" placeholder="Screenshot2..." accept="image/*"></b-form-file>
    </b-form-group>
    <b-form-group label="Screenshot 3" label-for="Screenshot 3">
      <b-form-file v-model="form.screenshot3" placeholder="Screenshot3..." accept="image/*"></b-form-file>
    </b-form-group>

    <template #modal-footer>
      <b-button variant="success" @click="submit()">
        ยืนยัน
      </b-button>
      <b-button variant="danger" @click="cancel()">
        ยกเลิก
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BTab, BTabs, BFormFile, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTab, BTabs, BFormFile, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
  },
  directives: {
    Ripple
  },
  props: ['form', 'editModal'],
  computed: {
    modal: {
      get() {
        return this.editModal;
      },
      set(value) {
        this.$emit('setModal', value);
      }
    }
  },
  emits: ['submit', 'setModal'],
  data: () => ({
    // modal: this.$props.editModal,
    gamesOption: [
      { text: 'SPORT', value: 1 },
      { text: 'CASINO', value: 2 },
      { text: 'SLOT', value: 3 },
    ],
  }),
  methods: {
    submit() {
      this.$emit('submit', this.$props.form)
      this.$emit('setModal', false)
      this.clearForm();
    },
    cancel() {
      this.$emit('setModal', false);
      this.clearForm();
    },
    clearForm() {
      this.form = {
      }
    }
  }
}
</script>